import React from "react";
import "./Insideloader.css"; // Assuming you have other custom styles in this file

const InsideLoader = () => {
  return (
    <div className="flex justify-center items-center h-screen -mb-20">
      <div className="spinner1"></div>
    </div>
  );
};

export default InsideLoader;
