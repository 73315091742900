import React from "react";

const Breaker = ({ breaker, setBreaker, dropdownData }) => {
  const Breakers = [
    "Amazon",
    "eBay",
    "Blowout Cards",
    "Steel City Collectibles",
  ];
  return (
    <>
      <form className="flex justify-center mt-8 ">
        <select
          id="sports"
          className="bg-transparent  border border-[#9E9E9E] outline-none w-[376px] h-[40px] rounded-[4px]"
          value={breaker}
          onChange={(e) => setBreaker(e.target.value)}
        >
          <option value="" disabled={true}>
            Select Breaker
          </option>

          {dropdownData?.breaker
            ?.sort((a, b) => a.breaker.localeCompare(b.breaker))
            .map((data) => (
              <option key={data?._id} value={data?.breaker}>
                {data?.breaker}
              </option>
            ))}
        </select>
      </form>
    </>
  );
};

export default Breaker;
