import React from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

const LineChart = ({ response }) => {
  let pricesArray = [];
  let labelsArray = [];
  let tooltipsArray = [];

  if (response && response.prices) {
    pricesArray = response.prices.map((price) => price.price);
    labelsArray = response.prices.map((price) => {
      const date = new Date(price.date);
      return date.toLocaleDateString();
    });
    tooltipsArray = response.prices.map(
      (price) => `${price.vendor} - $${price.price}`
    );
  }

  const data = {
    labels: labelsArray,
    datasets: [
      {
        label: "Breakcomp Price Comparison Chart",
        backgroundColor: "#0033FF",
        borderColor: "#0033FF",
        data: pricesArray,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => tooltipsArray[context.dataIndex],
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
