import React from "react";
import { Link } from "react-router-dom";
import brands from "./../../utils/brands";
import teams from "./../../utils/teams";

const CommonFeilds = ({
  year,
  setYear,
  brand,
  setBrand,
  team,
  setTeam,
  dropdownData,
}) => {
  return (
    <>
      <div className="flex flex-wrap justify-center items-center mt-10">
        <form className="flex flex-wrap justify-center items-center gap-4">
          <input
            className="w-full sm:w-48 border border-gray-300 rounded-md py-2 px-4 mb-2 sm:mb-0"
            type="number"
            id="year"
            name="year"
            placeholder="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />

          <select
            name="brand"
            id="brand"
            className="w-full sm:w-auto border border-gray-300 rounded-md py-2 px-4 mb-2 sm:mb-0"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          >
            <option value="" disabled>
              Select Brand
            </option>
            {dropdownData?.brands
              ?.sort((a, b) => a.brand.localeCompare(b.brand))
              .map((data, i) => (
                <option key={data?._id} value={data?.brand}>
                  {data?.brand}
                </option>
              ))}
          </select>

          <select
            name="team"
            id="team"
            className="w-full sm:w-auto border border-gray-300 rounded-md py-2 px-4 mb-2 sm:mb-0"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
          >
            <option value="" disabled>
              Select Team
            </option>
            {dropdownData?.teams
              ?.sort((a, b) => a.team.localeCompare(b.team))
              .map((data) => (
                <option key={data?._id} value={data?.team}>
                  {data?.team}
                </option>
              ))}
          </select>
        </form>
      </div>
    </>
  );
};

export default CommonFeilds;
