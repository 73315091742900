import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
// import card from "../../src/assets/img/card.jpg";
import LineChart from "../components/chart/LineChart";
import newRequest from "./../utils/newRequest";
import { urls } from "./../utils/urls";
// import Loader from "./../components/layout/Loader";
import InsideLoader from "../components/layout/InsideLoader";
export default function SportCard() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCard = async () => {
      try {
        setLoading(true);
        const response = await newRequest.get(`/admin/sport-card/${id}`);
        setData(response.data);
        // console.log(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCard();
  }, [id]);

  // console.log("data: ", data?.prices[0].sellerLink);

  return (
    <>
      {loading ? (
        <InsideLoader />
      ) : (
        <div className="font-Poppins px-4 md:container-box">
          <section className="flex flex-col md:flex-row md:px-20  pt-10 gap-4 items-center">
            <div className="w-full md:w-1/2">
              {data?.image && (
                <img
                  src={`${urls.baseImageURL}uploads/${data?.image}`}
                  alt={data?.team}
                  className="w-[600px] h-[325px] rounded-md object-cover"
                />
              )}
            </div>
            <div className="w-full md:w-1/2">
              <h1 className=" font-[1000] w-full md:w-[80%]">
                {data?.year} {data?.brand} {data?.sport} {data?.team} Trading
                Card {data?.boxOrCaseBreak}
              </h1>
              <div className="border-b-[3px] border-[#000000] py-4 flex gap-2">
                <strong>Breaker:</strong>
                <p>{data?.breaker}</p>
              </div>
              <div className="flex justify-between items-center py-4">
                <b className="text-[25px]"></b>
                <button
                  className="px-8 py-2 bg-[#0033FF] text-[white] rounded-sm"
                  onClick={() => {
                    const buyButton = document.querySelector("#buy-button");
                    buyButton.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Buy Now
                </button>
              </div>
              <div>
                <div className="flex w-[100%] border-y-2 border-[black] ">
                  <span className="bg-[#F0F2F2] w-[50%] py-2 font-bold pl-4">
                    Year
                  </span>
                  <span className="py-2 pl-4">{data?.year}</span>
                </div>
                <div className="flex  w-[100%] border-b-2 border-[black]">
                  <span className="bg-[#F0F2F2] w-[50%] py-2 font-bold pl-4">
                    Sports
                  </span>
                  <span className="py-2 pl-4">{data?.sport}</span>
                </div>
                <div className="flex w-[100%] border-b-2 border-[black]">
                  <span className="bg-[#F0F2F2] w-[50%] py-2 font-bold pl-4">
                    Brand
                  </span>
                  <span className="py-2 pl-4">{data?.brand}</span>
                </div>
              </div>
            </div>
          </section>

          <section className="px-4 md:px-20 container py-10">
            <div className="flex flex-col gap-4 w-[100%]">
              <b>Product Description</b>
              {/* <p>{data?.description}</p> */}
              <p
                className="overflow-y-auto"
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></p>
            </div>
          </section>
          <section className="flex justify-center items-center flex-col md:flex-row gap-4 px-4 md:px-20 py-10">
            <div className="w-full md:w-[50%]">
              <h1 className="font-bold">Vendors</h1>
              {data?.prices
                ?.sort((a, b) => a.price - b.price)
                .map((vendor, i) => (
                  <div
                    className="flex justify-between px-4  w-full md:w-[80%] shadow-md py-4 my-2 items-center"
                    key={i}
                  >
                    <div className="flex gap-2  w-[300px]">
                      <span className="font-bold">{i + 1}</span>
                      <p className="font-bold">{vendor?.vendor}</p>{" "}
                      {/* <span>({vendor?.seller})</span> */}
                    </div>
                    <span className=" flex justify-start  font-bold w-[100px]">
                      ${vendor.price}
                    </span>
                    {/* {console.log("data: ", vendor?.sellerLink)} */}
                    {vendor?.sellerLink && (
                      <a
                        href={`${
                          vendor?.sellerLink?.startsWith("http")
                            ? ""
                            : "http://"
                        }${vendor?.sellerLink}`}
                        target="_blank"
                        // rel="noopener noreferrer"
                      >
                        <button
                          id="buy-button"
                          className="px-4 py-[2px] bg-[#0033FF] text-[white] rounded-sm"
                        >
                          Buy
                        </button>
                      </a>
                    )}
                  </div>
                ))}
            </div>
            {data && (
              <div>
                <h1 className="font-bold">Graph</h1>
                <LineChart response={data} />
              </div>
            )}
          </section>
        </div>
      )}
    </>
  );
}
