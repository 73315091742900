import React from "react";
import sports from "./../../utils/sports";
import { useEffect } from "react";
import { useState } from "react";

const Sport = ({ sport, setSport, dropdownData }) => {
  return (
    <>
      <form className="flex justify-center my-8 ">
        <select
          id="sports"
          className="bg-transparent  border border-[#9E9E9E] outline-none w-[376px] h-[40px] rounded-[4px]"
          value={sport}
          onChange={(e) => setSport(e.target.value)}
        >
          <option defaultValue={""}>Select Sports</option>

          {dropdownData?.sports
            ?.sort((a, b) => a.sport.localeCompare(b.sport))
            .map((data) => (
              <option
                value={data?.sport}
                key={data?._id}
                className="text-lg text-gray-700"
              >
                {data?.sport}
              </option>
            ))}
        </select>
      </form>
    </>
  );
};

export default Sport;
