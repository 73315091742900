import React from "react";

const Price = ({ minPrice, setMinPrice, maxPrice, setMaxPrice }) => {
  return (
    <>
      <form className="mt-8">
  <div className="flex flex-col md:flex-row md:items-center md:justify-center md:gap-x-16">
    <input
      className="border-[1px] h-[40px] w-full md:w-[250px] border-[#9E9E9E] rounded-[4px] px-4 outline-none mb-4 md:mb-0 md:mr-4"
      type="number"
      id="minPrice"
      name="minPrice"
      value={minPrice}
      onChange={(e) => setMinPrice(e.target.value)}
      placeholder="Min Price"
    />

    <h1 className="text-[#423D68] font-semibold hidden md:block">To</h1>

    <input
      className="border-[1px] h-[40px] w-full md:w-[250px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
      type="text"
      id="maxPrice"
      name="maxPrice"
      value={maxPrice}
      onChange={(e) => setMaxPrice(e.target.value)}
      placeholder="Max Price"
    />
  </div>
</form>

    </>
  );
};

export default Price;
