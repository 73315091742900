import React, { useEffect, useState } from "react";
import Breaker from "../cardfeilds/Breaker";
import CommonFeilds from "../cardfeilds/CommonFeilds";
import Price from "../cardfeilds/Price";
import Sport from "../cardfeilds/Sport";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Modal from "../modal/Modal";
import cardImg1 from "../../assets/img/home1.png";
import cardImg2 from "../../assets/img/home2.png";
import cardImg3 from "../../assets/img/home3.png";
import cardImg4 from "../../assets/img/home4.png";
import newRequest from "./../../utils/newRequest";

const Card = () => {
  const [type, setType] = useState("pyt");
  const [year, setYear] = useState("");
  const [brand, setBrand] = useState("");
  const [team, setTeam] = useState("");
  const [sport, setSport] = useState("");
  const [breaker, setBreaker] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const [searchCount, setSearchCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const navigate = useNavigate();
  const [dropdownData, setDropdownData] = useState({});
  const fetchDropdownData = async () => {
    try {
      const response = await newRequest.get("/admin/dropdown");
      setDropdownData(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };
  useEffect(() => {
    fetchDropdownData();
    const user = localStorage.getItem("comparison-user");
    if (user) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (searchCount > 1 && !isLoggedIn) {
      setModalIsOpen(true);
    }
  }, [searchCount, isLoggedIn]);

  const handleSearch = () => {
    if (!isLoggedIn) {
      const count = parseInt(localStorage.getItem("searchCount") || 0);
      setSearchCount(count + 1);
      localStorage.setItem("searchCount", count + 1);
      if (count >= 2) {
        setModalIsOpen(true);
        return;
      }
    }
    navigate(
      `/sport-cards?year=${year}&brand=${brand || ""}&team=${
        team || ""
      }&type=pyt`
    );
  };
  const handleModalClose = () => {
    setModalIsOpen(false);
    // navigate("/login");
  };
  const handleSearchRandom = () => {
    if (!isLoggedIn) {
      const count = parseInt(localStorage.getItem("searchCount") || 0);
      setSearchCount(count + 1);
      localStorage.setItem("searchCount", count + 1);
      if (count >= 2) {
        setModalIsOpen(true);
        return;
      }
    }
    navigate(
      `/sport-cards?year=${year}&brand=${brand}&team=${team}&type=random`
    );
  };
  const handleSearchSport = () => {
    if (!isLoggedIn) {
      const count = parseInt(localStorage.getItem("searchCount") || 0);
      setSearchCount(count + 1);
      localStorage.setItem("searchCount", count + 1);
      if (count >= 2) {
        setModalIsOpen(true);
        return;
      }
    }
    navigate(
      `/sport-cards?year=${year}&brand=${brand}&team=${team}&sport=${sport}`
    );
  };
  const handleSearchBreaker = () => {
    if (!isLoggedIn) {
      const count = parseInt(localStorage.getItem("searchCount") || 0);
      setSearchCount(count + 1);
      localStorage.setItem("searchCount", count + 1);
      if (count >= 2) {
        setModalIsOpen(true);
        return;
      }
    }
    navigate(
      `/sport-cards?year=${year}&brand=${brand}&team=${team}&breaker=${breaker}`
    );
  };
  const handleSearchPrices = () => {
    if (!isLoggedIn) {
      const count = parseInt(localStorage.getItem("searchCount") || 0);
      setSearchCount(count + 1);
      localStorage.setItem("searchCount", count + 1);
      if (count >= 2) {
        setModalIsOpen(true);
        return;
      }
    }
    navigate(
      `/sport-cards?year=${year}&brand=${brand}&team=${team}&minPrice=${minPrice}&maxPrice=${maxPrice}`
    );
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onClose={handleModalClose}>
        <h2>Please log in to continue searching</h2>
        <span>
          <Link to="/login" className="text-blue-600">
            Click Here
          </Link>{" "}
          to Login
        </span>
      </Modal>
      <div className="flex justify-center pt-4">
        <span className="flex px-1 border border-[#00D62B] rounded-lg">
          <p className="text-[#0033FF] font-extrabold text-lg md:text-2xl">
            Immediately Giving You Price Comparisons From Credible Breakers
          </p>
          <p className="text-[#0033FF]  flex items-center pl-1 text-sm md:text-lg ">
            Try Now For Free
          </p>
        </span>
      </div>

      <div className="flex justify-around items-center card--component mt-8  ">
        <div className="w-[300px] hidden md:block">
          <img
            className="w-full h-[200px] object-contain "
            src={cardImg1}
            alt=""
          />
          <br />
          <img
            className="w-full h-[200px] object-contain "
            src={cardImg2}
            alt=""
          />
        </div>
        <div className="w-full md:w-[970px] h-[480px] md:h-[420px] bg-white rounded-md py-4 px-2 md:px-8 shadow--bg relative ">
          <div className="flex justify-around items-center">
            <button
              className={
                type === "pyt"
                  ? "flex items-center gap-x-2 border-[1px] border-[#0033FF] rounded-2xl p-2 bg-[#EDF0F3]"
                  : "flex gap-x-2 items-center"
              }
              onClick={() => setType("pyt")}
            >
              <img src="/Assets/pyp.svg" className="hidden md:block" />
              <p className="uppercase cursor-pointer text-[#0033ff]">
                Pyt
              </p>
            </button>
            <button
              className={
                type === "rendom"
                  ? "flex items-center gap-x-2 border-[1px] border-[#0033FF] rounded-2xl p-2 bg-[#EDF0F3]"
                  : "flex gap-x-2 items-center"
              }
              onClick={() => setType("rendom")}
            >
              <img src="/Assets/rendom.svg" className="hidden md:block" />
              <p className="cursor-pointer text-[#0033ff]  ">
                Random
              </p>
            </button>
            <button
              className={
                type === "sport"
                  ? "flex items-center gap-x-2 border-[1px] border-[#0033FF] rounded-2xl p-2 bg-[#EDF0F3]"
                  : "flex gap-x-2 items-center"
              }
              onClick={() => setType("sport")}
            >
              <img src="/Assets/sport.svg" className="hidden md:block" />
              <p className="cursor-pointer text-[#0033ff] ">
                Sport
              </p>
            </button>
            <button
              className={
                type === "breaker"
                  ? "flex items-center gap-x-2 border-[1px] border-[#0033FF] rounded-2xl p-2 bg-[#EDF0F3]"
                  : "flex gap-x-2 items-center"
              }
              onClick={() => setType("breaker")}
            >
              <img src="/Assets/breaker.svg" className="hidden md:block" />
              <p className="cursor-pointer text-[#0033ff] ">
                Breaker
              </p>
            </button>
            <button
              className={
                type === "price"
                  ? "flex items-center gap-x-2 border-[1px] border-[#0033FF] rounded-2xl p-2 bg-[#EDF0F3]"
                  : "flex gap-x-2 items-center"
              }
              onClick={() => setType("price")}
            >
              <div></div>
              <img src="/Assets/price.svg" className="hidden md:block" />
              <p className="cursor-pointer text-[#0033ff]  ">
                Price
              </p>
            </button>
          </div>
          {type === "pyt" ? (
            <>
              <div className="flex flex-wrap h-[200px] justify-center items-center">
                <CommonFeilds
                  dropdownData={dropdownData}
                  year={year}
                  setYear={setYear}
                  team={team}
                  setTeam={setTeam}
                  brand={brand}
                  setBrand={setBrand}
                />
              </div>
              <div className="absolute bottom-[10%] flex justify-between items-center w-[86%] ml-8 ">
                <button className="bg-[#EDF0F3] border-[2px] border-[#0033FF] rounded-[50px] w-[143px] h-[38px] text-center">
                  Pick your team
                </button>

                <button
                  className="bg-[#0033FF] text-[#EDF0F3] rounded-[4px] w-[180px] h-[48px] text-center shadow--bg"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </>
          ) : type === "rendom" ? (
            <>
              <div className="flex h-[200px] justify-center items-center">
                <CommonFeilds
                  dropdownData={dropdownData}
                  year={year}
                  setYear={setYear}
                  team={team}
                  setTeam={setTeam}
                  brand={brand}
                  setBrand={setBrand}
                />
                <div className="absolute bottom-[10%] flex justify-between items-center w-[86%] ml-8 ">
                  <button className="bg-[#EDF0F3] border-[2px] border-[#0033FF] rounded-[50px] w-[143px] h-[38px] text-center">
                    Random
                  </button>

                  <button
                    className="bg-[#0033FF]  text-[#EDF0F3] rounded-[4px] w-[180px] h-[48px] text-center"
                    onClick={handleSearchRandom}
                  >
                    Search
                  </button>
                </div>
              </div>
            </>
          ) : type === "sport" ? (
            <>
              <CommonFeilds
                dropdownData={dropdownData}
                year={year}
                setYear={setYear}
                team={team}
                setTeam={setTeam}
                brand={brand}
                setBrand={setBrand}
              />
              <Sport
                sport={sport}
                setSport={setSport}
                dropdownData={dropdownData}
              />
              <div className="absolute bottom-[10%] flex justify-between items-center w-[86%] ml-8 ">
                <button className="bg-[#EDF0F3] border-[2px] border-[#0033ff] rounded-[50px] w-[143px] h-[38px] text-center">
                  Sports
                </button>

                <button
                  className="bg-[#0033FF]  text-[#EDF0F3] rounded-[4px] w-[180px] h-[48px] text-center"
                  onClick={handleSearchSport}
                >
                  Search
                </button>
              </div>
            </>
          ) : type === "breaker" ? (
            <>
              <CommonFeilds
                dropdownData={dropdownData}
                year={year}
                setYear={setYear}
                team={team}
                setTeam={setTeam}
                brand={brand}
                setBrand={setBrand}
              />
              <Breaker
                breaker={breaker}
                setBreaker={setBreaker}
                dropdownData={dropdownData}
              />
              <div className="absolute bottom-[10%] flex justify-between items-center w-[86%] ml-8 ">
                <button className="bg-[#EDF0F3] border-[2px] border-[#0033ff] rounded-[50px] w-[143px] h-[38px] text-center">
                  Breaker
                </button>

                <button
                  className="bg-[#0033FF]  text-[#EDF0F3] rounded-[4px] w-[180px] h-[48px] text-center"
                  onClick={handleSearchBreaker}
                >
                  Search
                </button>
              </div>
            </>
          ) : type === "price" ? (
            <>
              <CommonFeilds
                dropdownData={dropdownData}
                year={year}
                setYear={setYear}
                team={team}
                setTeam={setTeam}
                brand={brand}
                setBrand={setBrand}
              />
              <Price
                minPrice={minPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                setMaxPrice={setMaxPrice}
                dropdownData={dropdownData}
              />
              <div className="bottom-[10%] pt-4 flex justify-between items-center w-[86%] ml-8 ">
                <button className="bg-[#EDF0F3] border-[2px] border-[#0033ff] rounded-[50px] w-[143px] h-[38px] text-center">
                  Price
                </button>

                <button
                  className="bg-[#0033FF]  text-[#EDF0F3] rounded-[4px] w-[180px] h-[48px] text-center"
                  onClick={handleSearchPrices}
                >
                  Search
                </button>
              </div>
            </>
          ) : null}
        </div>
        <div className="w-[300px] hidden md:block">
          <img
            className="w-full h-[200px] object-contain "
            src={cardImg3}
            alt=""
          />
          <br />
          <img
            className="w-full h-[200px] object-contain "
            src={cardImg4}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Card;
