export const urls = {
    baseURL: "https://api.breakcomp.com/api/",     // base URL for API requests

    baseImageURL: "https://api.breakcomp.com/",    // base url for image

    // baseURL: "http://localhost:8800/api/",     // base URL for API requests

    // baseImageURL: "http://localhost:8800/",    // base url for image

    login: `auth/login`,                       // login user

    signup: `auth/register`,                   // Register New User

    logout: `auth/logout`,                     // Register New User

    contectus: `auth/contect`,                 // Register New User

    newReleases: `admin/latest`,               // post a new sport card

    getSportCards: `admin/sport-cards`,        // get all sport cards 

    getUser: `user/`,                          // get user 

    updateUser: `user/update/`,                // get all sport cards 

    contactUs: `admin/contact`,                // Contact Us APi 

};