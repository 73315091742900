import React, { useEffect } from "react";
import { useState } from "react";
import {
  SORT_OPTIONS,
  YEAR_OPTIONS,
  BRAND_OPTIONS,
  SPORT_OPTIONS,
  TEAM_OPTIONS,
} from "./../../utils/options";
import newRequest from "../../utils/newRequest";

const Filter = ({
  sort,
  setSort,
  years,
  setYears,
  brands,
  setBrands,
  sports,
  setSports,
  teams,
  setTeams,
  searchParams,
  year,
  fetchSportCards,
  location,
}) => {
  const [dropdownData, setDropdownData] = useState({});

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await newRequest.get("/admin/dropdown");
        setDropdownData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, []);

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setYears(newYear);
    searchParams.set("year", newYear);
    window.history.pushState(
      {},
      "",
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  const handleBrandChange = (event) => {
    const brand = event.target.value;
    setBrands(brand);
    searchParams.set("brand", brand);
    window.history.pushState(
      {},
      "",
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  const handleSportChange = (event) => {
    const sport = event.target.value;
    setSports(sport);
    searchParams.set("sport", sport);
    window.history.pushState(
      {},
      "",
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  const handleTeamChange = (event) => {
    const team = event.target.value;
    setTeams(team);
    searchParams.set("team", team);
    window.history.pushState(
      {},
      "",
      `${location.pathname}?${searchParams.toString()}`
    );
  };

  const handleFilter = () => {
    window.location.reload();
  };

  return (
    <>
      <section>
        {/* <span className="text-[black]">Sort by</span>
        <hr className="bg-white text-black border-t-[1px] border-black font-Poppins w-65 mt-1" />
      
        <select
          className="py-2 w-[100%] mt-2 outline-none border-[1px] broder-[#9E9E9E] rounded-[4px]"
          onChange={handleSortChange}
          value={sort}
        >
          <option value="" className="hover:bg-black">
            Recommended
          </option>
          <option value="lowest-price">Lowest Price</option>
          <option value="highest-price">Highest Price</option>
        </select> */}

        <div className="mt-4">
          <span className="text-[black]">Year</span>
          <hr className="bg-white text-black border-t-[1px] border-black font-Poppins w-65 mt-1" />
          <div className="mt-2">
            <select
              id="year-dropdown"
              name="year"
              value={years}
              onChange={handleYearChange}
              className="pl-2 w-[100%] h-8 border-[1px] border-black "
            >
              {YEAR_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-4">
          <span className="text-[black]">Brand</span>
          <hr className="bg-white text-black border-t-[1px] border-black font-Poppins w-65 mt-1" />
          <div className="mt-2">
            {dropdownData?.brands?.map((brand) => (
              <label
                key={brand?._id}
                htmlFor={brand?._id}
                className="flex gap-2 items-center"
              >
                <input
                  className="w-4 h-4"
                  type="radio"
                  id={brand?._id}
                  name="brand"
                  value={brand?.brand}
                  onChange={handleBrandChange}
                  checked={brands?.includes(brand?.brand)}
                />
                {brand?.brand}
                <br />
              </label>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <span className="text-[black]">Sports</span>
          <hr className="bg-white text-black border-t-[1px] border-black font-Poppins w-65 mt-1 " />
          <div className="mt-2">
            {dropdownData?.sports?.map((sport) => (
              <div key={sport?._id} className="flex gap-2 items-center">
                <input
                  className="w-4 h-4"
                  type="radio"
                  id={sport?._id}
                  name="sport"
                  value={sport?.sport}
                  onChange={handleSportChange}
                  checked={sports === sport.sport}
                />
                <label htmlFor={sport?._id}>{sport?.sport}</label>
                <br />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <span className="text-[black]">Teams</span>
          <hr className="bg-white text-black border-t-[1px] border-black font-Poppins w-65 mt-1" />
          <div className="mt-2">
            {dropdownData?.teams?.map((team) => (
              <div key={team?._id} className="flex gap-2 items-center">
                <input
                  className="w-4 h-4"
                  type="radio"
                  id={team?._id}
                  name="team"
                  value={team?.team}
                  onChange={handleTeamChange}
                  checked={teams === team?.team}
                />
                <label htmlFor={team?._id}>{team?.team}</label>
                <br />
              </div>
            ))}
          </div>
        </div>

        <div className="mt-[40px] flex justify-center">
          <button
            className="bg-[#0033FF] text-white px-4 py-2 rounded-md"
            onClick={handleFilter}
          >
            Filter
          </button>
        </div>
      </section>
    </>
  );
};

export default Filter;
