import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/Price Comparison Logo.svg";
import newRequest from "../utils/newRequest";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  
  const [email, setEmail] = useState("")
  const [otp, setOTP] = useState("")

  const [password, setPassword] = useState("")
  const [newpassword, setNewPassword] = useState("")

  const navigate = useNavigate();
 

  const [forgetPassword, setForgetPassword] = useState(true)
  const [oTPCheck, setOTPCheck] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)

  const onSubmit =  async (e) => {
   e.preventDefault();

   const res = await toast.promise(newRequest.post("/auth/forget-password", {
    email:email.trim()
  }), {
    pending: "Sending email...",
  });
    // console.log("res   ::::: ",res.data);
    if(res.data.success){
      toast.success(res.data.message);
      localStorage.setItem("email" ,  JSON.stringify(email));
      setForgetPassword(false);
      setResetPassword(false);
      setOTPCheck(true);
    }
    else{
      toast.error(res.data.message);
    }
  }
  const onSubmitOTP =  async (e) => {
    e.preventDefault();

    const res = await toast.promise(newRequest.post("/auth/valid-token", {
      email: JSON.parse(localStorage.getItem("email")),
       otp
    }), {
      pending: "Verifying OTP..." 
    });
    //  console.log("res   ::::: ",res)
     if(res.data.success){
      toast.success(res.data.message)
       localStorage.setItem("otp" ,  JSON.stringify(otp));
       setForgetPassword(false);
       setResetPassword(true);
       setOTPCheck(false);
     }
     else{
      toast.error(res.data.message);
    }
   }


   const onSubmitResetPassword =  async (e) => {
    e.preventDefault();
    if(!password || !newpassword){
      toast.error("Enter valid Password");
    }
    if(password === newpassword){
     
      const res = await toast.promise(newRequest.post("/auth/reset-password", {
        email: JSON.parse(localStorage.getItem("email")),
        otp: JSON.parse(localStorage.getItem("otp")),
        password:password
      }), {
        pending: "Reseting Password...",
      });
      if(res.data.success){
        toast.success(res.data.message)
        localStorage.clear();
        navigate("/")
      }
      else{
        toast.error(res.data.message);
      }
    }else{
      toast.error("Password doesnot match");
    }
   }

  return (
    <>
      <div className="flex justify-between">
        <div className="mx-10 mt-5">
          <div className="absolute top-[5px] ">
            <img
              src={logo}
              className="h-full w-[220px] "
              alt="Price Comparison Logo"
            />
          </div>

          <div className="mt-36">
            <h1 className="text-3xl font-bold">Forget Password</h1>
            <p className="text-[16px/25px] mt-4 w-[450px] ">
              Enter the email associated with your account and we'll send an
              email with instructions to reset your password
            </p>
          </div>
          <div>
           {
            forgetPassword &&  <form className="mt-12" onSubmit={onSubmit} method="">
            <div className="flex flex-col justify-start items-center gap-y-6">
              <input
                className="border-[1px] h-[40px] w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                type="text"
                id="email"
                name="email"
                placeholder="Email Address*"
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <button
                className="border-[2px] font-medium border-[#5765FB] bg-[#5765FB]
                text-white py-3 w-[330px]  h-[50px] rounded-[4px]"
                type = "submit"
              > Send Instructions </button>

              <Link
                to="/login"
                className=" font-medium  text-center
                text-[#5765FB] py-3 w-[330px] h-[50px] "
              >
                Back to Login
              </Link>
            </div>
          </form>
           }

           {
            oTPCheck &&  <form className="mt-12" onSubmit={onSubmitOTP} method="">
            <div className="flex flex-col justify-start items-center gap-y-6">
              <input
                className="border-[1px] h-[40px] w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                type="text"
                id="otp"
                name="otp"
                placeholder="Enter OTP"
                onChange={(e) => setOTP(e.target.value)}
                required
              />

              <button
                className="border-[2px] font-medium border-[#5765FB] bg-[#5765FB]
                text-white py-3 w-[330px]  h-[50px] rounded-[4px]"
                type = "submit"
              > Verify OTP </button>

              <Link
                to="/login"
                className=" font-medium  text-center
                text-[#5765FB] py-3 w-[330px] h-[50px] "
              >
                Back to Login
              </Link>
            </div>
          </form>
           }

           {
            resetPassword &&  <form className="mt-12" onSubmit={onSubmitResetPassword} method="">
            <div className="flex flex-col justify-start items-center gap-y-6">
              <input
                className="border-[1px] h-[40px] w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                type="password"
                id="password"
                name="password"
                placeholder="Enter New Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <input
                className="border-[1px] h-[40px] w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                type="password"
                id="confirmpassword"
                name="confirmpassword"
                placeholder="Confirm Password"
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />

              <button
                className="border-[2px] font-medium border-[#5765FB] bg-[#5765FB]
                text-white py-3 w-[330px]  h-[50px] rounded-[4px]"
                type = "submit"
              > Reset Password </button>

              <Link
                to="/login"
                className=" font-medium  text-center
                text-[#5765FB] py-3 w-[330px] h-[50px] "
              >
                Back to Login
              </Link>
            </div>
          </form>
          }
          </div>
        </div>

        <div className="relative">
          <img
            src="/Assets/Shape.svg"
            className="h-[100vh] object-cover "
            alt=""
          />
          <img
            src="/Assets/Login Illustration.svg"
            className="absolute top-[20%] right-[10%]"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
