import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/layout/Loader";
import newRequest from "./../utils/newRequest";
import { urls } from "./../utils/urls";
import logo from "../assets/img/Price Comparison Logo.svg";
import { toast } from "react-toastify";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [error, setError] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(isChecked);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        setPasswordError("Passwords do not match*");
      } else if (!isChecked) {
        setPasswordError("Please Accept Our Terms of Use and Privacy Policy");
      } else {
        setPasswordError("");
        const res = await toast.promise(newRequest.post(`${urls.signup}`, {
          username,
          email,
          state,
          password,
        }),{
          pending: "Registering Account!"
        })
        if(res.data.success){
          toast.success(res.data.message)
          navigate("/login");
        }else{
          toast.error(res.data.message)
        }
      }
    } catch (err) {
      setError(err.response.data);
    }
  };

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("comparison-user"));
    if (currentUser !== null) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col md:flex-row justify-between ">
          <div className="mx-0 md:mx-20">
            <div className="mt-[140px] px-4">
              <div className="absolute top-[2px] ">
                <img
                  src={logo}
                  className="h-full w-[220px] "
                  alt="Price Comparison Logo"
                />
              </div>
              <h1 className="text-3xl font-bold">Create Account</h1>
              <p className="text-[16px/25px] mt-4">
                Fill the form below to create an account
              </p>
            </div>
            <div>
              <form className="mt-8 px-4" onSubmit={handleSubmit}>
                <div className="flex flex-col items-center gap-y-6">
                  <input
                    className="border-[1px] h-[40px] w-full md:w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Full Name*"
                    required
                  />

                  <input
                    className="border-[1px] h-[40px] w-full md:w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Address*"
                    required
                  />

                  <input
                    className="border-[1px] h-[40px] w-full md:w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                    type="text"
                    id="state"
                    name="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    placeholder="State*"
                    required
                  />

                  <input
                    className="border-[1px] h-[40px] w-full md:w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password*"
                    required
                  />
                  <input
                    className="border-[1px] h-[40px] w-full md:w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password*"
                    required
                  />
                  <span className="text-[red]">{passwordError}</span>
                  <div className="flex justify-center w-[100%]">
                    <input
                      type="checkbox"
                      id="remember"
                      value={isChecked}
                      onClick={() => setIsChecked(!isChecked)}
                      className="bg-gray-300"
                    />
                    <label
                      htmlFor="remember"
                      className="text-[#B6B6B8] text-sm"
                    >
                      I accept to the{" "}
                      <span className="font-medium">Terms of use</span> and{" "}
                      <span className="font-medium">Privacy Policy</span>.
                    </label>
                  </div>

                  <input
                    className="border-[2px] font-bold border-[#5765FB] bg-[#5765FB]
                text-white py-3 w-[330px] h-[50px] rounded-[4px] hover:bg-transparent
                hover:text-[#5765FB]"
                    type="submit"
                    value="Create Account"
                  />
                </div>
              </form>
              <div className="text-center mt-4">
                <p>
                  Already Have an account?{" "}
                  <Link to="/login" className="text-[#5765FB] font-medium">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="relative hidden md:block">
            <img src="/Assets/Shape.svg" className="h-[100vh] object-cover " />
            <img
              src="/Assets/Login Illustration.svg"
              className="absolute top-[20%] right-[10%]"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
