export const SORT_OPTIONS = [
    { label: "Recommended", value: "" },
    { label: "Lowest Price", value: "low-to-high" },
    { label: "Highest Price", value: "high-to-low" },
];
export const YEAR_OPTIONS = [
    { label: "2023", value: "2023" },
    { label: "2022", value: "2022" },
    { label: "2021", value: "2021" },
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
];
export const BRAND_OPTIONS = [
    { label: "Panini", value: "Panini" },
    { label: "Topps", value: "Topps" },
    { label: "Score", value: "Score" },
    { label: "Upper Deck", value: "Upper Deck" },
];
export const SPORT_OPTIONS = [
    { label: "Football", value: "Football" },
    { label: "Baseball", value: "Baseball" },
    { label: "Hockey", value: "Hockey" },
    { label: "Basketball", value: "Basketball" },
];
export const TEAM_OPTIONS = [
    { label: "New York Yankees", value: "New York Yankees" },
    { label: "Los Angeles Lakers", value: "Los Angeles Lakers" },
    { label: "Dallas Cowboys", value: "Dallas Cowboys" },
    { label: "Chicago Blackhawks", value: "Chicago Blackhawks" },
];
