import React, { useEffect, useState } from "react";
import axios from "axios";
import { urls } from "./../utils/urls";
import newRequest from "./../utils/newRequest";
import { useNavigate } from "react-router-dom";

const UpdateProfile = () => {
  const [user, setUser] = useState({});
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    country: "",
    phone: "",
    desc: "",
  });
  const navigate = useNavigate();

  const currentUser = JSON.parse(localStorage.getItem("comparison-user"));
  const userId = currentUser._id;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await newRequest.put(`${urls.updateUser}${userId}`, {
        ...formData,
      });

      console.log(response);
      const data = response.data;
      setUser(data);
      console.log(data);
      localStorage.setItem("comparison-user", JSON.stringify(data));
      setEdit(false);
    } catch (error) {
      // console.log("here");
      if (error.response.status === 401) {
        localStorage.removeItem("comparison-user");
        alert("Session Expired, Please Login again to Update User Profile");
        navigate("/login");
      }
      console.error(error.response.status === 401);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await newRequest.get(`${urls.getUser}${userId}`);
        setUser(data);
        setFormData({
          username: data?.username || "",
          email: data?.email || "",
          country: data?.country || "",
          phone: data?.phone || "",
          desc: data?.desc || "",
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, [edit]);
  return (
    <>
      {edit ? (
        <div className="container-box">
          <div className="flex justify-center items-center flex-col">
            <h1 className="text-5xl font-bold py-4">Update Profile</h1>
            <h2 className="text-lg font-bold">Manage your profile settings</h2>
            <div className="bg-white py-8 ">
              <form className="space-y-5">
                <div className="flex flex-col">
                  <label htmlFor="usernames">Full Name: </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    className="w-[400px] border-2 border-gray-200 p-2 rounded outline-none focus:border-#9E9E9E"
                    placeholder="Enter Your Full Name*"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="password">Email: </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-[400px] border-2 border-gray-200 p-2 rounded outline-none focus:border-[#9E9E9E]"
                    placeholder="Enter Your Email*"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="password">Country: </label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    className="w-[400px] border-2 border-gray-200 p-2 rounded outline-none focus:border-[#9E9E9E]"
                    placeholder="Enter Your Country*"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="password"> Contact Number: </label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-[400px] border-2 border-gray-200 p-2 rounded outline-none focus:border-#9E9E9E"
                    placeholder="Enter Your Contact Details*"
                  />
                </div>

                <div className="flex justify-center">
                  <button
                    className="block   bg-[#0033FF] py-3 px-12 rounded text-white transition duration-300"
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-screen ">
          <div className="bg-white rounded-lg shadow-2xl p-8 max-w-md w-full">
            <h1 className="text-3xl font-bold mb-8 text-center">
              {user?.username}'s Dashboard
            </h1>
            <div className="grid grid-cols-2 gap-5 mb-6">
              <div>
                <p className="font-bold">Full Name</p>
                <p>{user?.username || "N/A"}</p>
              </div>
              <div>
                <p className="font-bold">Email</p>
                <p>{user?.email || "N/A"}</p>
              </div>
              <div>
                <p className="font-bold">Country</p>
                <p>{user?.country || "N/A"}</p>
              </div>
              <div>
                <p className="font-bold">Phone</p>
                <p>{user?.phone || "N/A"}</p>
              </div>
            </div>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 ease-in-out"
              onClick={() => setEdit(!edit)}
            >
              Edit Profile
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateProfile;
