import React, { useState,useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { MdCall, MdLocationPin } from "react-icons/md";
import { urls } from "../utils/urls";
import newRequest from "./../utils/newRequest";

const ContectUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await newRequest.post(`${urls.contactUs}`, {
        name,
        email,
        phone,
        message,
      });
      console.log(response.data.message);
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setMessageSent(true);
      setTimeout(() => {
        setMessageSent(false);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="container">
        <div className="flex flex-col justify-center items-center gap-4 text-center px-2 md:px-8 lg:px-20">
          <h2 className="text-4xl font-bold">Get In Touch</h2>
          <p className="w-full text-lg font-medium">
            We would love to hear from you! If you have any questions, feedback,
            or inquiries, please don't hesitate to get in touch with us.
          </p>
        </div>
        <div className="px-8 py-4 my-8 rounded lg:shadow-xl flex flex-col gap-6 w-full lg:flex-row">
          <div className="flex flex-col gap-28 md:gap-48 h-[468px] w-full shadow-md bg-[#0033FF] rounded-md text-white my-6 py-4 px-4 md:px-12">
            <div>
              <h1 className="text-2xl font-bold">Have a Query in Mind?</h1>
              <p className="font-[300] space-y-4 pt-4 text-gray-300">
                We value your feedback and are always here to assist you. If you
                have any questions, suggestions, or concerns, please reach out
                to us using the contact information provided below. Our team
                will respond to your inquiry as soon as possible. Thank you for
                choosing us!
              </p>
            </div>
            <div className="flex">
              <p className="flex gap-4 text-gray-300 font-[300] justify-center items-center">
                {" "}
                <AiOutlineMail size={30} className="text-white" />
                contact@breakcomp.com
              </p>
              {/* <p className="flex gap-4 py-4 text-gray-300 font-[300]">
                <MdCall size={30} className="text-white" />
                +12642125655
              </p> */}
              {/* <p className="flex gap-4 text-gray-300 font-[300]">
                <MdLocationPin size={30} className="text-white" />
                New York, Berlin
              </p> */}
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center justify-center">
              <div className="bg-white py-8 ">
                <form className="space-y-5" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-1 font-bold text-gray-500"
                    >
                      Name
                    </label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className=" w-[300px] md:w-[500px]  border-2 border-gray-200 p-2 rounded outline-none focus:border-#9E9E9E"
                      placeholder="Name*"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-1 font-bold text-gray-500"
                    >
                      Email
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="w-[300px] md:w-[500px] border-2 border-gray-200 p-2 rounded outline-none focus:border-#9E9E9E"
                      placeholder="Email*"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="block mb-1 font-bold text-gray-500"
                    >
                      Phone
                    </label>
                    <input
                      type="number"
                      name="name"
                      id="name"
                      className="w-[300px] md:w-[500px]  border-2 border-gray-200 p-2 rounded outline-none focus:border-#9E9E9E"
                      placeholder="Phone*"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="message"
                      className="block mb-1 font-bold text-gray-500"
                    >
                      Message
                    </label>
                    <textarea
                      name="message"
                      type="textarea"
                      id="message"
                      className="w-[300px] md:w-[500px] border-2 border-gray-200 p-2 rounded outline-none focus:border-#9E9E9E"
                      placeholder="Message*"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                    />
                  </div>
                  <div className="flex">
                    <button className="block  bg-[#00D62B] py-2 px-8 rounded text-white transition duration-300">
                      Submit
                    </button>
                    {messageSent && (
                      <span className="mt-2 ml-5 text-[#00D62B] font-bold">
                        Successfully Sent !
                      </span>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContectUs;
