import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Filter from "../components/filter/Filter";
import SportCard from "../components/sportcart/SportCard";
import { ImCross } from "react-icons/im";
import { Link, useLocation } from "react-router-dom";
import newRequest from "./../utils/newRequest";

export default function SportCards() {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sportCards, setSportCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  //Filter
  const [sort, setSort] = useState("");
  const [years, setYears] = useState("");
  const [brands, setBrands] = useState("");
  const [sports, setSports] = useState("");
  const [teams, setTeams] = useState("");

  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);

  let type = searchParams.get("type");
  let year = searchParams.get("year");
  let sport = searchParams.get("sport");
  let brand = searchParams.get("brand");
  let team = searchParams.get("team");
  let breaker = searchParams.get("breaker");
  let minPrice = searchParams.get("minPrice");
  let maxPrice = searchParams.get("maxPrice");

  // console.log("URL YEAR: ", year);

  const fetchSportCards = async () => {
    setLoading(true);
    try {
      const timestamp = Date.now();
      const response = await newRequest.get(
        `/admin/filtered?type=${type || ""}&year=${year || ""}&sport=${
          sport || ""
        }&brand=${brand || ""}&team=${team || ""}&breaker=${
          breaker || ""
        }&minPrice=${minPrice || ""}&maxPrice=${
          maxPrice || ""
        }&page=${currentPage}&timestamp=${timestamp}`
      );

      setSportCards(response.data.sportCards);
      setFilteredData(response.data.sportCards);
      setTotalPages(response.data.totalPages);
      setHasNextPage(response.data.hasNextPage);
      setHasPrevPage(response.data.hasPrevPage);
      // console.log(response.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchSportCards();
  }, [
    type,
    sport,
    year,
    brand,
    team,
    minPrice,
    maxPrice,
    years,
    brands,
    sports,
    teams,
    currentPage,
  ]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    const filtered = sportCards?.filter(
      (d) =>
        d?.year.toString().toLowerCase().includes(query) ||
        d?.brand.toLowerCase().includes(query) ||
        d?.sport.toLowerCase().includes(query) ||
        d?.team.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
    setSearch(query);
  };
  return (
    <>
      <div className="px-4 md:px-20">
        <section className="flex flex-col sm:flex-row justify-between items-center my-5">
          <div className="flex flex-col items-center sm:flex-row gap-4">
            {type && (
              <span className="max-w-[300px] bg-[#F4F6F8] flex items-center justify-center gap-2 rounded-sm cursor-pointer shadow-md hover:shadow-lg px-3 py-2">
                Category:{" "}
                <span>{type === "pyt" ? "Pick Your Team" : "Random"}</span>
              </span>
            )}
            {year && (
              <span className="max-w-[300px] bg-[#F4F6F8] flex items-center justify-center gap-2 rounded-sm cursor-pointer shadow-md hover:shadow-lg px-3 py-2">
                {year}
              </span>
            )}
            {brand && (
              <span className="max-w-[300px] bg-[#F4F6F8] flex items-center justify-center gap-2 rounded-sm cursor-pointer shadow-md hover:shadow-lg px-3 py-2">
                {brand}
              </span>
            )}
            {sport && (
              <span className="max-w-[300px] bg-[#F4F6F8] flex items-center justify-center gap-2 rounded-sm cursor-pointer shadow-md hover:shadow-lg px-3 py-2">
                {sport}
              </span>
            )}
            {team && (
              <span className="max-w-[300px] bg-[#F4F6F8] flex items-center justify-center gap-2 rounded-sm cursor-pointer shadow-md hover:shadow-lg px-3 py-2">
                {team}
              </span>
            )}
            {breaker && (
              <span className="max-w-[300px] bg-[#F4F6F8] flex items-center justify-center gap-2 rounded-sm cursor-pointer shadow-md hover:shadow-lg px-3 py-2">
                {breaker}
              </span>
            )}

            {minPrice || maxPrice ? (
              <span className="max-w-[300px] bg-[#F4F6F8] flex items-center justify-center gap-2 rounded-sm cursor-pointer shadow-md hover:shadow-lg px-3 py-2">
                Price Range: {minPrice}$ - {maxPrice}$
              </span>
            ) : (
              ""
            )}
            <Link>
              <span className="text-[#00D62B] cursor-pointer">
                Clear Filters
              </span>
            </Link>
          </div>

          <div className="flex justify-end my-4 sm:my-0">
            <div className="relative">
              <CiSearch
                size={23}
                className="absolute inset-y-0 left-0 mt-2 ml-2 text-[#B6B6B8]"
              />
              <input
                className="border-[1px] border-[#9E9E9E] w-full sm:w-[376px] h-[40px] outline-none rounded-[10px] px-4 pl-10"
                placeholder="Search"
                type="search"
                id="gsearch"
                name="gsearch"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
        </section>
        <section className="flex justify-between px-4 md:container-box gap-x-8">
          <div className="w-1/4 hidden md:block">
            <Filter
              sort={sort}
              setSort={setSort}
              years={years}
              setYears={setYears}
              brands={brands}
              setBrands={setBrands}
              sports={sports}
              setSports={setSports}
              teams={teams}
              setTeams={setTeams}
              year={year}
              location={location}
              searchParams={searchParams}
              fetchSportCards={fetchSportCards}
            />
          </div>
          <div className="w-full md:w-3/4">
            <SportCard
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              hasNextPage={hasNextPage}
              hasPrevPage={hasPrevPage}
              totalPages={totalPages}
              loading={loading}
            />
          </div>
        </section>
      </div>
    </>
  );
}
