import React from 'react'
import Card from '../components/home/Card'
import Table from '../components/home/Table'
import { useEffect } from 'react'
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
  <Card/>
  <Table/>
    </>
  )
}

export default Home