import { Link } from "react-router-dom";
import { useEffect } from "react";
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);
  return (
    <>
      <div className="py-4 px-28">
        <h2 className="text-3xl font-bold text-center mb-8">PRIVACY POLICY</h2>
        <div className="pb-2">
          <i>Last modified: August 11, 2023</i>
        </div>
        <p className="text-base">
          This Privacy Policy applies to the BreakComp website{" "}
          <a
            href="https://breakcomp.com/"
            target="_blank"
            className=" text-blue-500"
          >
            (https://breakcomp.com/)
          </a>{" "}
          and mobile or tablet application (if any) (collectively, the
          "Website") owned and operated by BreakComp LLC, a Pennsylvania limited
          liability company ("BreakComp", "we" or "us"). This Privacy Policy
          describes how BreakComp collects, uses, shares and secures the
          personal information that you may provide to us when you visit the
          Website. It also describes your choices regarding use, access,
          correction and deletion of your personal information.
        </p>
        <div className="flex flex-col gap-4 py-4">
          <h3 className=" font-bold text-lg">How We Collect Information</h3>
          <p>We collect the following personal information from you:</p>
          <div className="flex gap-4 flex-col px-8">
            <h4 className="font-bold text-base">Information that you provide to us about yourself</h4>
          <p className="text-base">
            When you register for an account on the Website or contact us by
            completing a form on the Website, we may ask you for your name,
            telephone number and/or email address. If you do not provide this
            information, we may not be able to respond to your request.
          </p>
          <h4 className="font-bold text-base">Information collected automatically</h4>
          <p className="text-base">
            When you visit the Website, we may use cookies and similar
            technologies to collect information about your location, and your
            activities on the Website, information about your device and log
            information. This is described in more detail below. These cookies
            and similar technologies may collect anonymized Internet protocol
            (IP) addresses, browser type, Internet service provider (ISP),
            referring/exit pages, the files viewed on our Website (e.g., HTML
            pages, graphics, etc.), operating system, date/time stamp and/or
            clickstream data. BreakComp may leverage third party technologies,
            such as Google Analytics, to analyze trends, administer the Website,
            track users' movements around the Website and gather demographic
            information about our visitors for the purpose of analytics. You can
            control the use of cookies at the individual browser level, but if
            you choose to disable cookies, it may limit your use of certain
            features or functions on the Website.
          </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 py-4">
          <h3 className="font-bold text-base">Purpose and Legal Basis of the Processing</h3>
          <p className="text-base">
            To the extent EU data protection law applies to our processing of
            personal information about you, we act as the "data controller." <br className="pb-4" /> 
            <i>To perform the Services:</i> We may use your information to identify you
            and communicate with you about our products and services. The legal
            basis for these uses is the performance of a contract with you.<br className="pb-4" />  
            To Optimize or improve the Services: We may use your information to
            authenticate you, prevent fraud and abuse and perform research. The
            legal basis for these uses is our legitimate interest in the
            improvement and optimization of our offerings and in ensuring the
            security of our Website, and we apply appropriate safeguards to
            protect your personal information as described in further detail
            below.
          </p>
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3 className="font-bold text-base">How We Share Your Information</h3>
          <p className="text-base">
            We share personal information with service providers, as required by
            law and in connection with the protection and enforcement of our
            legal and contractual rights. <br className="pb-4" /> 
            <i>With Service Providers:</i> We may share your personal information with third parties who provide services on
            our behalf to help with our business activities. This includes
            providing sending marketing communications, conducting research and
            analysis and providing cloud computing infrastructure. The legal
            basis for sharing this information is our legitimate interest in
            providing our products and services efficiently, and we implement
            measures to safeguard your personal information. <br className="pb-4" /> <i>With Public
            Authorities or Law Enforcement:</i> In certain situations, BreakComp may
            be required to disclose personal information in response to lawful
            requests by public authorities, including to meet national security
            or law enforcement requirements. We may also disclose your personal
            information as required by law (such as to comply with a subpoena or
            other legal process), when we believe in good faith that disclosure
            is necessary to protect our rights, when we believe there is a
            violation to our <Link to="/terms-of-use" className="text-blue-500">Terms of Use</Link>, to
            protect your safety or the safety of others, to investigate fraud,
            to respond to a government request, if we are involved in a merger,
            acquisition or sale of all or a portion of our business or assets,
            or if we are involved in a bankruptcy or liquidation proceeding. The
            legal basis for this is our legitimate interest in protecting our
            legal rights and those of others, compliance with legal obligations
            and our legitimate interest in fulfilling legal obligations imposed
            by legal authorities outside of the EU.<br className="pb-4" />  <i>Prevent fraud and abuse of
            the Services:</i> We will share personal information to prevent or
            detect fraud or to address technical issues and if we believe it is
            necessary to investigate, prevent or take action regarding
            situations that involve abuse of the Website infrastructure. The
            legal basis for this is our legitimate interest in maintaining the
            security of our Website. <br className="pb-4" /> We also process and share information in an
            aggregated, de-identified manner where the information is shared as
            part of a statistical report and does not contain personal
            information.
          </p>
        </div>
        <div className="flex flex-col gap-4 py-4">
          <h3 className="font-bold text-base">Security</h3>
          <p className="text-base">
            The security of your personal information is important to us. We
            implement reasonable measures to protect the personal information
            submitted to us, both during transmission and once it is received.
            BreakComp takes steps to ensure that all personal information
            remains private and confidential. We restrict access to personal
            information to BreakComp employees, contractors and agents who need
            to know that information in order to operate, develop or improve our
            Website or offerings. These individuals are bound by confidentiality
            obligations and may be subject to discipline, including termination
            and criminal prosecution, if they fail to meet these obligations. If
            you have any questions about the security of your personal
            information, you can contact us at the contact information below.
          </p>
        </div>
        <div className="flex flex-col gap-4 py-4">
          <h3 className="font-bold text-base">Rights with Respect to Personal Information</h3>
          <p className="text-base">
            Following our receipt of a verified request about your personal
            information, BreakComp will provide you with information about
            whether we hold any of your personal information. You may access,
            correct, obtain a copy of or request deletion of your personal
            information by contacting us at the contact information below. We
            will respond to your request within a reasonable timeframe. In
            certain circumstances we may be required by law to retain your
            personal information, or we may need to retain your personal
            information in order to continue providing a service. You may also
            have the right to make a GDPR complaint to the relevant Supervisory
            Authority. A list of Supervisory Authorities is available here:{" "}
            <a
              target="_blank"
              className=" text-blue-500"
              href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            >
              {" "}
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            .
          </p>
        </div>
        <div className="flex flex-col gap-4 py-4">
          <h3 className="font-bold text-base">Your Choices with Respect to Personal Information</h3>
          <p className="text-base">
            We use and disclose personal information in ways that are consistent
            with your expectations and choices. Accordingly, we will provide you
            with the opportunity to choose (opt out) whether your personal
            information is to be (a) disclosed to a third party or (b) used for
            a purpose that is materially different from the purpose(s) for which
            it was collected or subsequently authorized. You will be provided
            with clear, conspicuous and readily available mechanisms to exercise
            your choices. To do so, please email us your request any time at the
            contact information below, and we will respond to your request
            within a reasonable timeframe.<br className="pb-4" />  <i>Promotional Email communication:</i> You
            may have the option to sign-up to receive email or newsletters or
            other communications from us. If you would like to discontinue
            receiving this information, you may update your email preferences by
            using the "Unsubscribe" link found in emails we send to you or by
            contacting us at the contact information below.
          </p>
        </div>
        <div className="flex flex-col gap-4 py-4">
          <h3 className="font-bold text-base">Data Retention</h3>
          <p className="text-base">
            We may retain your information for as long as needed to provide you
            services, comply with our legal obligations, resolve disputes and
            enforce our agreements. In certain circumstances we may be required
            by law to retain your personal information, or we may need to retain
            your personal information in order to continue providing a service.
          </p>
        </div>
        <div className="flex flex-col gap-4 py-4">
          <h3 className="font-bold text-base">Policy Regarding Children</h3>
          <p className="text-base">
            The Website is not directed to children under the age of 16. We do
            not knowingly collect personal information from children under 16.
            If you are under 16, please do not use the Website and do not
            provide any personal information to us. If you become aware that a
            child under 16 has provided us with personal information without the
            consent of a parent or legal guardian, please contact us at the
            contact information below.
          </p>
        </div>
        <div className="flex flex-col gap-4 py-4">
          <h3 className="font-bold text-base">
            Transfers of Personal Information Not from the EEA, the UK or
            Switzerland
          </h3>
          <p className="text-base">
            To the extent that personal information is transferred to the United
            States from countries other than countries within the European
            Economic Area, the United Kingdom or Switzerland, including personal
            information that is transferred from Canada, we employ similar
            safeguards such as selecting third party vendors carefully, ensuring
            personal information will be used and disclosed only as set out in
            this Privacy Policy and ensuring that it is protected by appropriate
            security safeguards.<br className="pb-4" />  However, in connection with these transfers of
            personal information, your personal information may be subject to
            privacy laws that may not provide the same protection as your
            country of residence. For example, government entities in such other
            countries may have certain rights to access your personal
            information. By using this Website, you are consenting to this
            transfer of your personal information.
          </p>
        </div>
        <div className="flex flex-col gap-4 py-4">
          <h3 className="font-bold text-base">Changes to This Privacy Policy</h3>
          <p className="text-base">
            We may need to update this policy from time to time. Unless
            otherwise required by law, we will notify of such changes by
            updating the "Last modified" date at the top of this Privacy Policy.
            We encourage you to periodically review this page for the latest
            information on our privacy practices. By continuing to use the
            Website, you are agreeing to and accepting the then-current version
            of this Privacy Policy.
          </p>
        </div>
        <div className="flex flex-col gap-4 py-4">
          <h3 className="font-bold text-base">Privacy Questions / Feedback</h3>
          <p className="text-base">
            If you have questions or concerns about this Privacy Policy, please
            contact us at <a href="mailto:gmail.com" target="_blank" className="text-blue-500">contact@breakcomp.com</a>.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
