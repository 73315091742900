import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { urls } from "./../utils/urls";
import newRequest from "./../utils/newRequest";
import Loader from "../components/layout/Loader";

import logo from "../assets/img/Price Comparison Logo.svg";

const Login = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        setError("Incorrect Email or Password");
      } else {
        setError("");
        setLoading(true);
        const res = await newRequest.post(`${urls.login}`, {
          email,
          password,
        });
        localStorage.setItem("comparison-user", JSON.stringify(res.data));
        localStorage.removeItem("searchCount");
        navigate("/");
        setLoading(false);
      }
    } catch (err) {
      setError(err.response.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    const currentUser = JSON.parse(localStorage.getItem("comparison-user"));
    if (currentUser !== null) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col md:flex-row justify-between px-4">
          <div className="mx-10 mt-5">
            <div className="absolute top-[2px] ">
              <img
                src={logo}
                className="h-full w-[220px] "
                alt="Price Comparison Logo"
              />
            </div>

            <div className="mt-36">
              <h1 className="text-3xl font-bold">Log in</h1>
              <p className="text-[16px/25px] mt-4">
                Enter your credentials to proceed
              </p>
            </div>
            <div>
              <form className="mt-12" onSubmit={handleSubmit}>
                <div className="flex flex-col items-center gap-y-6">
                  <input
                    className="border-[1px] h-[40px] w-full md:w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Address*"
                    required
                  />

                  <input
                    className="border-[1px] h-[40px] w-full md:w-[376px] border-[#9E9E9E] rounded-[4px] px-4 outline-none"
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password*"
                    required
                  />
                  <span className="text-[red]">{error}</span>
                  <div className="flex justify-between w-[100%]">
                    <label className="text-[#B6B6B8] font-medium">
                      <input
                        type="checkbox"
                        name="remember"
                        // checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                      />
                    </label>

                    <Link
                      to="/forget-password"
                      className="text-[#5765FB] font-medium"
                    >
                      Forget Password?
                    </Link>
                  </div>

                  <input
                    className="border-[2px] font-bold border-[#5765FB] bg-[#5765FB]
                  text-white py-3 w-[330px] h-[50px] rounded-[4px] hover:bg-transparent
                  hover:text-[#5765FB]"
                    type="submit"
                    value="Login"
                  />
                </div>
              </form>
              <div className="text-center mt-4">
                <p>
                  Don't have an account?{" "}
                  <Link to="/signup" className="text-[#5765FB] font-medium">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="relative hidden md:block">
            <img src="/Assets/Shape.svg" className="h-[100vh] object-cover " />
            <img
              src="/Assets/Login Illustration.svg"
              className="absolute top-[20%] right-[10%]"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
