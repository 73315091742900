import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import card from "../../assets/img/card.jpg";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import newRequest from "./../../utils/newRequest";
import { urls } from "./../../utils/urls";
import InsideLoader from "../layout/InsideLoader";
const SportCard = ({
  filteredData,
  currentPage,
  setCurrentPage,
  hasNextPage,
  hasPrevPage,
  totalPages,
  loading,
}) => {
  function handlePageClick(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function renderButton(i) {
    return (
      <li key={i}>
        <button
          className={`mx-1 flex h-9 w-9 items-center justify-center rounded-full ${
            i === currentPage
              ? "bg-[#0033FF] text-white"
              : "bg-transparent text-blue-gray-500 border border-blue-gray-100 hover:bg-light-300"
          }`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      </li>
    );
  }

  function renderPageNumbers() {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - 4);
    let endPage = Math.min(totalPages, currentPage + 5);

    if (currentPage <= 6) {
      endPage = Math.min(totalPages, 10);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(renderButton(i));
    }

    if (totalPages > endPage) {
      pageNumbers.push(
        <li key="ellipsis">
          <span>...</span>
        </li>
      );
      pageNumbers.push(renderButton(totalPages));
    }

    return pageNumbers;
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  // console.log("--------------------------------", filteredData);
  return (
    <>
      <div className="flex flex-col gap-6 ">
        {loading ? (
          <InsideLoader />
        ) : filteredData?.length > 0 ? (
          filteredData?.map((data) => (
            <section
              className="flex flex-col md:flex-row justify-between shadow-lg rounded-l-md "
              key={data._id}
            >
              <div className=" rounded-l-md">
                <img
                  src={`${urls.baseImageURL}uploads/${data?.image}`}
                  alt={data?.team}
                  className="w-full md:w-[260px] h-full rounded-l-md object-cover "
                />
              </div>
              <div className=" flex flex-col gap-4 border-r-[3px] w-full  md:w-[460px] border-none  md:border-[#00D62B] py-4 px-4">
                <h3 className="font-bold w-[70%] text-[#0033FF]">
                  {data?.year} {data?.brand} {data?.sport} {data?.team} Trading
                  Card {data?.boxOrCaseBreak}
                </h3>

                {/* {data?.description.length > 140 ? (
                    <span>{data?.description.slice(0, 40 * 4)}......</span>
                  ) : (
                    data?.description
                  )} */}
                <p
                  className="overflow-y-auto"
                  style={{ whiteSpace: "pre-wrap", maxHeight: "20vh" }}
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                ></p>

                <div className="flex flex-wrap md:flex-row gap-8 border-none md:border-t-[3px] border-[#00D62B] pt-2 w-full md:w-[460px]">
                  {data?.prices
                    .slice(0, 3)
                    .sort((a, b) => a.price - b.price)
                    .map((vendor, index) => (
                      <div className="flex flex-col gap-2" key={index}>
                        <label>{vendor.vendor}</label>
                        <span>${vendor.price}</span>
                      </div>
                    ))}

                  {data?.prices?.length > 3 && (
                    <div className="flex flex-col gap-2 w-[100px] md:w-auto rounded transition duration-300 ease-in-out pl-[5px]">
                      <select className="outline-none">
                        <option value="">
                          {data?.prices?.length - 3} more
                        </option>
                        {data?.prices
                          ?.slice(3)
                          .sort((a, b) => a.price - b.price)
                          .map((vendor, index) => (
                            <option
                              value={`${vendor.price} ${vendor.vendor}`}
                              key={index}
                              disabled
                            >
                              {`${vendor.price}$ ${vendor.vendor}`}
                            </option>
                          ))}
                      </select>
                      <span className="pl-[5px]">
                        {data?.prices[3]?.vendor}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex  flex-row  md:flex-col pb-4 gap-6 justify-center items-center lg:w-[200px] ">
                <h1 className="font-bold text-4xl">
                  $
                  {Math.min(...data.prices.map((priceData) => priceData.price))}
                </h1>
                {/* <NavLink to={`/sport-card/${data._id}`}></NavLink> */}
                <NavLink to={`/sport-card/${data._id}`}>
                  <button className="bg-[#0033FF] text-white rounded-sm px-3 py-2">
                    View
                  </button>
                </NavLink>
              </div>
            </section>
          ))
        ) : (
          <div className="flex justify-center item-center">
            <h1>NO RECORD FOUND </h1>
          </div>
        )}
        <ul className="flex justify-end w-[100%] flex-wrap">
          {hasPrevPage && (
            <li>
              <span className="material-icons text-sm mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0  text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 cursor-pointer">
                <AiOutlineLeft
                  onClick={() => setCurrentPage(currentPage - 1)}
                />
              </span>
            </li>
          )}
          {renderPageNumbers()}
          {hasNextPage && (
            <li>
              <span
                className="material-icons text-sm mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0  text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 cursor-pointer"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <AiOutlineRight />
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default SportCard;
