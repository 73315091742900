import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaTwitter, FaFacebookF } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { AiOutlineMail } from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa";
import newRequest from "../../utils/newRequest";
import { urls } from "../../utils/urls";
const Footer = () => {
  const [adsData, setAdsData] = useState();
  const fetchAds = async () => {
    const response = await newRequest.get("/ad");
    if (response.data.success) {
      // setAdsData(response.data.data)
      const tempObj = {};
      for (let i = 0; i < response.data.data.length; i++) {
        if (response.data.data[i].position === "Footer ABOVE") {
          tempObj.footerABOVE = response.data.data[i];
        }
      }
      setAdsData(tempObj);
    }
  };
  useEffect(() => {
    fetchAds();
  }, []);

  return (
    <>
      <section className="footer mt-24 font-Poppins bg-white text-black mb-10 px-8  md:container-box lg:container-box">
        {/* <div className='text-center mb-12'>
          <a href={adsData?.footerABOVE?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.footerABOVE?.title}<span className='text-black font-normal'> -</span></a>
      </div> */}
        {adsData?.footerABOVE && (
          <div className="text-center mb-5">
            {(adsData?.footerABOVE?.thirdPartyImageUrl ||
              adsData?.footerABOVE?.imageURL) && (
              <a href={adsData?.footerABOVE?.redirectionURL} target="_blank">
                <div className="max-w-xs mx-auto my-4 p-4 bg-white border border-gray-200 rounded-lg shadow-md">
                  <img
                    src={
                      adsData?.footerABOVE?.thirdPartyImageUrl
                        ? adsData?.footerABOVE?.thirdPartyImageUrl
                        : `${urls.baseImageURL}uploads/ads/${adsData?.footerABOVE?.imageURL}`
                    }
                    alt="Ad"
                    className="w-full h-auto"
                  />
                </div>
              </a>
            )}
            <a
              href={adsData?.footerABOVE?.redirectionURL}
              target="_blank"
              className="text-blue-400 text-[1.5rem] font-bold"
            >
              <span className="text-black font-normal">- </span>
              {adsData?.footerABOVE?.title}
              <span className="text-black font-normal"> -</span>
            </a>
          </div>
        )}
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row  md:justify-between md:gap-x-3">
            <div className="w-full  md:w-1/4 lg:w-1/4">
              <div className="">
                <div className="">
                  <Link to="/">
                    <h1 className="text-[#000] font-bold ">
                      Breakcomp - Sport Card Price Comparison
                    </h1>
                  </Link>
                </div>
                <p className="pt-3 text-[14px/21px] font-medium m-0 text-base">
                  BreakComp is a card price comparison website that caters to
                  collectors and enthusiasts of sports cards, providing them
                  with a convenient platform to compare prices across various
                  vendors and sellers.
                </p>
              </div>
            </div>
            <div className="w-full  md:w-1/5 lg:w-1/5">
              <div className="text-[#000] font-bold text-x">
                <h3>Office</h3>
                <ul className="p-0">
                  {/* <li className="font-medium flex pb-3 gap-2 pt-1 items-baseline">
                    25 El <br /> Dorado St. <br /> Ravenna, OH 44266
                  </li> */}

                  <a
                    className="text-black no-underline"
                    href="mailto:admin@pricecomparison.com "
                  >
                    <li className="font-medium flex flex-wrap pb-3 gap-2 pt-1 items-center">
                      contact@breakcomp.com{" "}
                    </li>
                  </a>
                  {/* <a
                    className="text-black no-underline"
                    href="tel:(+92)308-8888034"
                  >
                    {" "}
                    <li className=" flex pb-3 gap-2 pt-1 items-center font-bold">
                      (+92)308-8888034
                    </li>
                  </a> */}
                </ul>
              </div>
            </div>

            <div className="w-full md:w-1/5 lg:w-1/5">
              <div className="">
                <h3 className="text-[#000] font-bold text-x">Links</h3>
                <ul className="pt-4">
                  <Link className="text-black no-underline" to="/">
                    {" "}
                    <li className=" pb-3"> Home </li>
                  </Link>
                  {/* <li className=" pb-3">About</li> */}
                  {/* <Link className="text-black no-underline" to='/privacy-policy'>

                  <li className=" pb-3">Privacy Policy</li>
                  </Link> */}
                  {/* <Link className="text-black no-underline" to='/terms-of-use'>

                  <li className=" pb-3">Terms of Use</li>
                  </Link> */}

                  <Link className="text-black no-underline" to="/contact-us">
                    {" "}
                    <li className=" pb-3">Contact Us</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="w-full md:w-1/5 lg:w-1/5">
              <div className="">
                <h3 className="text-[#000] font-bold text-x">Newsletter</h3>
                <div className="pt-4 flex gap-2 items-center">
                  <AiOutlineMail size={20} />
                  <input
                    type="text"
                    placeholder="Enter your email address"
                    className="border-none focus:outline-none w-full"
                  />
                  <FaArrowRight size={20} className="pl-2" />
                </div>
                <hr className="bg-white text-black border-t-[1px] border-black font-Poppins w-[70%] md:w-65 lg:w-65 mt-1" />
                <div className="flex px-1 pt-4 ">
                  <a
                    href="https://www.facebook.com/groups/breakcomp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebookF
                      size={25}
                      className="p-[4px] mr-2 text-white  bg-black rounded-full"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/breakcompcards/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiOutlineInstagram
                      size={25}
                      className="p-[4px] mr-2 text-white  bg-black rounded-full    "
                    />
                  </a>
                  {/* <a
                    href="https://www.linkedin.com/company/status-200/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GrLinkedinOption
                      size={25}
                      className="p-[4px] mr-2 text-white  bg-black rounded-full     "
                    />
                  </a> */}
                  <a
                    href="https://twitter.com/BreakCompCards"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitter
                      size={25}
                      className="p-[4px] mr-2 text-white  bg-black rounded-full   "
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center py-2">
            <p className="text-center">
              <span className=" font-bold">Disclaimer:</span> When a link is
              clicked on to merchant websites and make a purchase, this may
              result in BreakComp earning a commission. Affiliate programs and
              affiliations include, but are not limited to, the eBay Partner
              Network.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-white text-black border-t-[1px] border-black font-Poppins container-box">
        <div className="container py-2">
          <div className="row flex justify-between">
            <div className="flex justify-center md:justify-start lg:justify-start">
              <div className="p-0">
                <p className="m-0 text-xs md:text-sm lg:text-base ">
                  copyright@Status200. All rights are reserved
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 flex justify-center md:justify-end lg:justify-end">
              <div>
                <ul className="flex justify-end gap-10 m-0 p-0 ">
                  <Link
                    className="text-black no-underline"
                    to="/privacy-policy"
                  >
                    <li className="text-xs md:text-sm lg:text-base ">
                      Privacy Policy{" "}
                    </li>
                  </Link>

                  <Link
                    className="text-black no-underline"
                    to="/terms-of-use"
                    // to="/terms-and-condition"
                  >
                    <li className="text-xs md:text-sm lg:text-base  ">
                      Terms of Use
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
