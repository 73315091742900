import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import newRequest from "./../../utils/newRequest";
import { urls } from "./../../utils/urls";
import Loader from "./Loader";
import { ReactComponent as Logo } from "../../assets/img/Price Comparison Logo.svg";
import profile from "../../assets/img/green.png";
const Navbar = () => {
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  // const [currentUser, setCurrentUser] = useState();
  let currentUser = JSON.parse(localStorage.getItem("comparison-user"));
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      setLoading(true);
      await newRequest.post(`${urls.logout}`);
      localStorage.removeItem("comparison-user");
      currentUser = null;
      setTimeout(() => {
        setLoading(false);
      }, 500);
      localStorage.setItem("searchCount", 0);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   setCurrentUser(JSON.parse(localStorage.getItem("comparison-user")));
  // }, [currentUser]);

  const [adsData, setAdsData] = useState();
  const fetchAds = async () => {
    const response = await newRequest.get("/ad");
    if(response.data.success){
      // setAdsData(response.data.data)
      const tempObj = {}
      for(let i = 0; i<response.data.data.length; i++){
        if(response.data.data[i].position === "Navigation Bar"){
          tempObj.navigationBarAd = response.data.data[i]
        }
      }
      setAdsData(tempObj)
    }
  }
  useEffect(()=>{
    fetchAds();
  },[])
  return (
    <>
  {loading && <Loader />}
  <div className="flex flex-col md:flex-row justify-between mt-2 items-center px-4 md:px-20 md:container-box">
    <span className="h-[8rem] w-[16rem]" >
      <Link  to="/">
        <Logo />
      </Link>
    </span>
    {adsData?.navigationBarAd && 
      <div className='text-center mb-5'>
            {(adsData?.navigationBarAd?.thirdPartyImageUrl || adsData?.navigationBarAd?.imageURL) && (
              <a href={adsData?.navigationBarAd?.redirectionURL} target="_blank" >
              <div className="mx-auto my-4 p-2 bg-white border border-gray-200 rounded-lg shadow-md flex flex-wrap">
                <img
                  src={adsData?.navigationBarAd?.thirdPartyImageUrl ? adsData?.navigationBarAd?.thirdPartyImageUrl : `${urls.baseImageURL}uploads/ads/${adsData?.navigationBarAd?.imageURL}`}
                  // src="https://archive.smashing.media/assets/344dbf88-fdf9-42bb-adb4-46f01eedd629/6676aa3c-eb37-49da-9e4f-f8d4a8321294/setting-height-width-images-important-again.png"
                  alt="Ad"
                  className="w-full h-auto object-obtain"
                  style={{ maxWidth: '100%', height: 'auto', maxHeight: '50px' }}
                />
              </div>
              </a>
            )}
            <a href={adsData?.navigationBarAd?.redirectionURL} target="_blank" className='text-blue-400 text-[1.2rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.navigationBarAd?.title}<span className='text-black font-normal'> -</span></a>
      </div> 
    }
    {/* <div className='text-center mb-5'>
        <a href={adsData?.navigationBarAd?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.navigationBarAd?.title}<span className='text-black font-normal'> -</span></a>
    </div> */}
    {currentUser != null ? (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "30px",
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() => setToggle(!toggle)}
        >
          <img src={profile} alt="" className="h-10 sm:h-12 mr-2 sm:mr-4" />
          <span className="text-sm sm:text-base font-medium">
            {currentUser?.username || "User"}{" "}
          </span>
          {toggle && (
            <>
              <div className="absolute flex flex-col bg-white top-full left-0 w-full shadow-md z-10">
                <span
                  onClick={handleLogout}
                  className="cursor-pointer py-2 px-4 text-sm sm:text-base text-gray-700 hover:text-white hover:bg-gray-700 rounded-t-lg sm:rounded-none sm:rounded-l-lg"
                >
                  Logout
                </span>
                <Link to="/update-profile">
                  <span className="cursor-pointer py-2 px-4 text-sm sm:text-base text-gray-700 hover:text-white hover:bg-gray-700 rounded-b-lg sm:rounded-none sm:rounded-r-lg">
                    Settings
                  </span>
                </Link>
              </div>
            </>
          )}
        </div>
      </>
    ) : (
      <div className="flex gap-x-3 h-10 justify-between items-center">
        <Link
          to="/login"
          className="border-[1px] w-[100px] text-center border-[#00D62B] text-[#00D62B]
         bg-white rounded-[4px] px-2 py-2 hover:text-white hover:bg-[#00D62B]"
        >
          Login
        </Link>
        <Link
          to="/signup"
          className="border-[1px] w-[100px] text-center border-[#00D62B] text-white 
         bg-[#00D62B] rounded-[4px] px-2 py-2 hover:text-[#00D62B] hover:bg-white"
        >
          Sign Up
        </Link>
      </div>
    )}
  </div>
  <div className="flex flex-col md:flex-row justify-center my-4 mx-4">
      <a href="#newly-posted-price-comps" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2 mb-2">
        Newly Posted Price Comps
      </a>
      <a href="#new-releases" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2 mb-2">
        New Releases & Checklists
      </a>
    </div>
</>

  );
};

export default Navbar;
