import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
// import card from "../../src/assets/img/card.jpg";
import LineChart from "../components/chart/LineChart";
import newRequest from "./../utils/newRequest";
import { urls } from "./../utils/urls";
// import Loader from "./../components/layout/Loader";
import InsideLoader from "../components/layout/InsideLoader";

export default function NewReleasePage() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCard = async () => {
      try {
        setLoading(true);
        const response = await newRequest.get(`/admin/new-release/${id}`);
        setData(response.data);
        // console.log(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCard();
  }, [id]);

  // console.log("data: ", data?.prices[0].sellerLink);

  return (
    <>
      {loading ? (
        <InsideLoader />
      ) : (
        <div className="font-Poppins px-4 md:container-box">
          <section className="flex flex-col md:px-20 pt-10 gap-4 items-center">
            <div className="w-[450px]  flex justify-center items-center">
              {data?.image && (
                <img
                  src={`${urls.baseImageURL}uploads/${data?.image}`}
                  alt={data?.team}
                  className=" h-full w-full rounded-md object-content"
                />
              )}
            </div>
            <div className="w-full">
              <h1 className=" font-[1000] text-3xl w-full flex justify-center">
                {data?.year} {data?.brand} {data?.sport} {data?.team} Trading
                Card {data?.boxOrCaseBreak}
              </h1>
            </div>
          </section>

          <section className="px-4 md:px-20 container py-10">
            <div className="flex flex-col text-xl gap-4 w-full">
              <b>Product Description</b>
              {/* <p>{data?.description}</p> */}
              <p
                className="overflow-y-auto"
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></p>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
