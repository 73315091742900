import React, { useEffect, useState } from "react";
import Loader from "./../layout/Loader";
import { urls } from "./../../utils/urls";
import newRequest from "./../../utils/newRequest";
import { Link, NavLink } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import InsideLoader from "../layout/InsideLoader";
const Table = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [cardsToShow, setCardsToShow] = useState(5);
  const [featuredToShow, setFeaturedToShow] = useState(20);
  const [loading, setLoading] = useState(false);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [adsData, setAdsData] = useState();

  // console.log(search);

  const handleViewMore = () => {
    setCardsToShow(cardsToShow + 5);
  };
  const handleViewLess = () => {
    setCardsToShow(cardsToShow - 5);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    const filtered = data?.filter(
      (d) =>
        d?.year.toString().toLowerCase().includes(query) ||
        d?.brand.toLowerCase().includes(query) ||
        d?.sport.toLowerCase().includes(query) ||
        d?.team.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
    setSearch(query);
  };

  const fetchNewReleasedCards = async () => {
    try {
      setLoading(true);

      const response = await newRequest.get("/admin/new-releases");
      const currentDate = new Date();

      // Convert all dates to Eastern Time Zone and sort in ascending order
      const sortedData = response.data
        .map((card) => ({
          ...card,
          releaseDate: new Date(card.releaseDate).toLocaleString(),
        }))
        .sort((a, b) => {
          const dateA = new Date(a.releaseDate);
          const dateB = new Date(b.releaseDate);
          return dateA - dateB;
        });

      // Split into future and past arrays
      const futureData = sortedData.filter(
        (card) => new Date(card.releaseDate) >= currentDate
      );
      const pastData = sortedData
        .filter((card) => new Date(card.releaseDate) < currentDate)
        .reverse();

      const finalData = [...futureData, ...pastData];
      setData(finalData);
      setFilteredData(finalData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchFeaturedProducts = async () => {
    try {
      setLoadingFeatured(true);

      const response = await newRequest.get("/admin/sport-cards-30");
      setData(response.data);
      // console.log("Featured Products", response.data);
      setFeaturedProducts(response.data);
      // console.log(data);
      setTimeout(() => {
        setLoadingFeatured(false);
      }, 500);
    } catch (error) {
      console.error(error);
      setLoadingFeatured(false);
    }
  };

  const fetchAds = async () => {
    const response = await newRequest.get("/ad");
    if(response.data.success){
      // setAdsData(response.data.data)
      const tempObj = {
        insideFeaturedProducts: [],
        insideReleaseDate: [],
      }
      for(let i = 0; i<response.data.data.length; i++){
        if(response.data.data[i].position === "Top 20 Featured Products ABOVE"){
          tempObj.featuredProductsABOVE = response.data.data[i]
        }
        else if(response.data.data[i].position === "Inside Top 20 Featured Products"){
          tempObj.insideFeaturedProducts.push(response.data.data[i])
        }
        else if(response.data.data[i].position === "Release Date ABOVE"){
          tempObj.releaseDateABOVE = response.data.data[i]
        }
        else if(response.data.data[i].position === "Inside Release Date"){
          tempObj.insideReleaseDate.push(response.data.data[i])
        }
      }
      setAdsData(tempObj)
    }
  }

  useEffect(() => {
    fetchNewReleasedCards();
    fetchFeaturedProducts();
    fetchAds();
  }, []);

console.log(adsData)
 
  return (
    <>
      {featuredProducts.length == 0 ? (
        <Loader />
      ) : (
        <div className="flex  justify-center my-32">
          {/* <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5 px-4"> */}
          <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            {/* <div className="flex justify-center md:justify-end w-full  my-4 relative">
              <div className="">
                <CiSearch
                  size={23}
                  className="mt-2 ml-2 absolute text-[#B6B6B8] "
                />
                <input
                  className="border-[1px] border-[#9E9E9E] w-full md:w-[376px] h-[40px] outline-none rounded-[10px] px-4 pl-10"
                  placeholder="Search"
                  type="search"
                  id="search"
                  name="search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div> */}

            <div className="flex justify-center flex-col">
              {/* <div className="py-2 inline-block w-full sm:px-6 lg:px-8"> */}
              <div className="py-2 inline-block w-full sm:px-6">
                <div className="overflow-hidden rounded-tr-[10px] rounded-tl-[10px] ">
                { adsData?.featuredProductsABOVE &&
                  <div className='text-center mb-5'>
                      {(adsData?.featuredProductsABOVE?.thirdPartyImageUrl || adsData?.featuredProductsABOVE?.imageURL) && (
                        <a href={adsData?.featuredProductsABOVE?.redirectionURL} target="_blank">
                        <div className="max-w-xs mx-auto my-4 p-4 bg-white border border-gray-200 rounded-lg shadow-md">
                          <img
                            src={adsData?.featuredProductsABOVE?.thirdPartyImageUrl ? adsData?.featuredProductsABOVE?.thirdPartyImageUrl : `${urls.baseImageURL}uploads/ads/${adsData?.featuredProductsABOVE?.imageURL}`}
                            alt={adsData?.featuredProductsABOVE?.title}
                            className="w-full h-auto"
                          />
                        </div>
                        </a>
                      )}
                      <a href={adsData?.featuredProductsABOVE?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.featuredProductsABOVE?.title}<span className='text-black font-normal'> -</span></a>
                  </div>
                }
                  {/* <div className="grid grid-cols-3 justify-items-center gap-8 px-10 md:px-2 w-full"> */}
                  {/* <div className="grid grid-cols-3 justify-items-center gap-8 md:px-2 w-full"> */}
                    {/* <div className='text-[#0033FF] text-lg font-bold self-center text-left col-start-1 col-end-1'>
                        <a href={adsData?.featuredProductsLEFT?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.featuredProductsLEFT?.title}<span className='text-black font-normal'> -</span></a>
                    </div> */}
                    <table className="col-span-10 w-full" id="newly-posted-price-comps">
                      <thead className="bg-[#0033FF] border-b text-white font-semibold">
                        <tr>
                          <th className="text-sm p-6 text-left font-semibold">
                            Top 20 Featured Products
                          </th>
                        </tr>
                      </thead>
                      {loadingFeatured ? (
                        <InsideLoader />
                      ) : (
                        <tbody className="text-black">
                          {featuredProducts.slice(0, featuredToShow).map((data, i) => (
                            <div>
                            <tr
                              className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                              key={data._id}
                            >
                              <td className="text-sm font-light p-6 whitespace-normal md:whitespace-nowrap cursor-pointer hover:text-blue-500">
                                <NavLink to={`/sport-card/${data._id}`}>
                                  <div className="flex gap-5">
                                    <img
                                      src={`${urls.baseImageURL}uploads/${data?.image}`}
                                      alt=""
                                      className="h-[40px] w-[40px] md:h-[70px] md:w-[70px] rounded-lg"
                                    />

                                    <p className="text-base md:text-[19px] flex items-center">
                                      {data?.year} {data?.brand} {data?.sport}{" "}
                                      {data?.team} {data?.boxOrCaseBreak}
                                    </p>
                                  </div>
                                </NavLink>
                              </td>
                            </tr>

                            { (i+1) % 3 === 0 && adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1] &&
                            <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                              <td className="text-sm font-light p-6 whitespace-normal md:whitespace-nowrap cursor-pointer hover:text-blue-500">
                                <NavLink to={adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.redirectionURL} target="_blank">
                                  <div className="flex gap-5">
                                    <img
                                      src={adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl ? adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl : `${urls.baseImageURL}uploads/ads/${adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.imageURL}`}
                                      alt={adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.title}
                                      className="h-[40px] w-[40px] md:h-[70px] md:w-[70px] rounded-lg"
                                    />

                                    <p className="text-base md:text-[19px] flex items-center">
                                      {adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.title}
                                    </p>
                                  </div>
                                </NavLink>
                              </td> </tr>}
                              </div>
                            // { (i+1) % 3 === 0 && adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1] &&
                            //   <div className='text-center mb-5'>
                            //     {/* {alert(i)} */}
                            //     {(adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl || adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.imageURL) && (
                            //       <a href={adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.redirectionURL} target="_blank">
                            //       <div className="max-w-xs mx-auto my-4 p-4 bg-white border border-gray-200 rounded-lg shadow-md">
                            //         <img
                            //           src={adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl ? adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl : `${urls.baseImageURL}uploads/ads/${adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.imageURL}`}
                            //           alt="Ad"
                            //           className="w-full h-auto"
                            //         />
                            //       </div>
                            //       </a>
                            //     )}
                            //     <a href={adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.insideFeaturedProducts[i==2 ? 0 : ((i+1)/3)-1]?.title}<span className='text-black font-normal'> -</span></a>
                            // </div> }
                            // </div>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {/* <div className='text-[#0033FF] text-lg font-bold self-center text-right col-start-12 col-end-12'>
                        <a href={adsData?.featuredProductsRIGHT?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.featuredProductsRIGHT?.title}<span className='text-black font-normal'> -</span></a>
                    </div> */}
                  {/* </div> */}

                </div>
              </div>

              <div className="flex flex-col justify-center md:justify-end w-full relative px-8 !mt-20">
              { adsData?.releaseDateABOVE &&
                <div className='text-center mb-5'>
                      {(adsData?.releaseDateABOVE?.thirdPartyImageUrl || adsData?.releaseDateABOVE?.imageURL) && (
                        <a href={adsData?.releaseDateABOVE?.redirectionURL} target="_blank">
                        <div className="max-w-xs mx-auto my-4 p-4 bg-white border border-gray-200 rounded-lg shadow-md">
                          <img
                            src={adsData?.releaseDateABOVE?.thirdPartyImageUrl ? adsData?.releaseDateABOVE?.thirdPartyImageUrl : `${urls.baseImageURL}uploads/ads/${adsData?.releaseDateABOVE?.imageURL}` }
                            alt={adsData?.releaseDateABOVE?.title}
                            className="w-full h-auto"
                          />
                        </div>
                        </a>
                      )}
                      <a href={adsData?.releaseDateABOVE?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.releaseDateABOVE?.title}<span className='text-black font-normal'> -</span></a>
                </div>
              }
                <div className="flex items-center justify-center">
                  {/* <CiSearch
                    size={23}
                    className="mt-[20px] ml-2 absolute text-[#B6B6B8]"
                  /> */}
                  <input
                    className="border-[1px] border-[#9E9E9E] w-full md:w-[500px] h-[60px] outline-none rounded-[10px] px-4 pl-10 text-lg"
                    placeholder="Search"
                    type="search"
                    id="search"
                    name="search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              {/* <div className="grid grid-cols-3 justify-items-center gap-8 md:px-2 w-full"> */}
                {/* <div className='text-[#0033FF] text-lg font-bold self-center text-left col-start-1 col-end-1'>
                    <a href={adsData?.releaseDateLEFT?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.releaseDateLEFT?.title}<span className='text-black font-normal'> -</span></a>
                </div> */}
                <div className="col-span-10 py-2 inline-block w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden rounded-tr-[10px] rounded-tl-[10px] "  id="new-releases">
                    <p className="bg-[#0033FF]  border-b text-white font-medium p-[22px]">
                      Release Date
                    </p>
                    
                    <table className=" px-10 md:px-2 w-full lg:w-[1000px]">
                      {/* <thead className="bg-[#0033FF]  border-b text-white font-semibold">
                        <tr>
                          <th className="text-sm  p-6 text-left">Release Date</th>
                          <th className="text-sm  p-6 text-left">Recently Added</th>
                        </tr>
                      </thead> */}
                      <tbody className="text-black">
                        {filteredData.length == 0 ? (
                            <InsideLoader />
                            ) :  filteredData.slice(0, cardsToShow).map((data, i) => (
                          <div>
                            <tr
                              className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                              key={data._id}
                            >
                              <td className="pl-2 whitespace-normal md:whitespace-nowrap text-sm font-medium text-blue-900 font-bold w-[120px]">
                                {data?.releaseDate &&
                                  new Date(data.releaseDate).toLocaleDateString()}
                              </td>
                              <td className="text-sm font-light p-6 whitespace-normal md:whitespace-nowrap cursor-pointer font-black hover:text-blue-500">
                                <NavLink to={`/new-release/${data._id}`}>
                                  <div className="flex align-center">
                                    <div className="flex gap-3  ">
                                      <img
                                        src={`${urls.baseImageURL}uploads/${data?.image}`}
                                        alt=""
                                        style={{ objectFit: "cover" }}
                                        className="h-[40px] w-[40px] md:h-[70px] md:w-[70px] rounded-lg "
                                      />
                                      <p className="flex text-base md:text-[19px] items-center">
                                        {data?.year} {data?.brand} {data?.sport}{" "}
                                        {data?.team}
                                      </p>
                                    </div>
                                  </div>
                                </NavLink>
                              </td>
                            </tr>
                            { (i+1) % 3 === 0 && adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1] &&
                              <tr
                                className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                // key={data._id}
                              >
                                <td className="pl-2 whitespace-normal md:whitespace-nowrap text-sm font-medium text-blue-900 font-bold w-[120px]">
                                    {new Date(adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.createdAt).toLocaleDateString()}
                                </td>
                                <td className="text-sm font-light p-6 whitespace-normal md:whitespace-nowrap cursor-pointer font-black hover:text-blue-500">
                                  <NavLink to={adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.redirectionURL} target="_blank">
                                    <div className="flex align-center">
                                      <div className="flex gap-3  ">
                                        <img
                                          src={adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl ? adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl : `${urls.baseImageURL}uploads/ads/${adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.imageURL}`}
                                          alt={adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.title}
                                          style={{ objectFit: "cover" }}
                                          className="h-[40px] w-[40px] md:h-[70px] md:w-[70px] rounded-lg "
                                        />
                                        <p className="flex text-base md:text-[19px] items-center">
                                          {adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.title}
                                        </p>
                                      </div>
                                    </div>
                                  </NavLink>
                                </td>
                              </tr>
                            }
                            {/* { (i+1) % 3 === 0 && adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1] &&
                              <div className='text-center mb-5'>
                                {(adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl || adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.imageURL) && (
                                  <a href={adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.redirectionURL} target="_blank">
                                  <div className="max-w-xs mx-auto my-4 p-4 bg-white border border-gray-200 rounded-lg shadow-md">
                                    <img
                                      src={adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl ? adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.thirdPartyImageUrl : `${urls.baseImageURL}uploads/ads/${adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.imageURL}`}
                                      alt="Ad"
                                      className="w-full h-auto"
                                    />
                                  </div>
                                  </a>
                                )}
                                <a href={adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.insideReleaseDate[i==2 ? 0 : ((i+1)/3)-1]?.title}<span className='text-black font-normal'> -</span></a>
                            </div> } */}
                          </div>
                        ))}
                      </tbody>
                    </table>
                    {cardsToShow < data.length && (
                      <div className="flex justify-center pt-10  ">
                        <button
                          className="bg-[#00D62B] px-2 py-1 rounded-sm text-white font-Poppins"
                          onClick={handleViewMore}
                        >
                          View More
                        </button>
                      </div>
                    )}
                    {cardsToShow > data.length && data.length > 5 && (
                      <div className="flex justify-center pt-10  ">
                        <button
                          className="bg-[#00D62B] px-2 py-1 rounded-sm text-white font-Poppins"
                          onClick={handleViewLess}
                          // disabled={cardsToShow.length}
                        >
                          View Less
                        </button>
                      </div>
                    )}
                  </div>
                  {/* <div className='text-center mt-10'>
                      <a href={adsData?.footerABOVE.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.footerABOVE?.title}<span className='text-black font-normal'> -</span></a>
                  </div> */}
                </div>
                {/* <div className='text-[#0033FF] text-lg font-bold self-center text-right col-start-12 col-end-12'>
                    <a href={adsData?.releaseDateRIGHT?.redirectionURL} target="_blank" className='text-blue-400 text-[1.5rem] font-bold'><span className='text-black font-normal'>- </span>{adsData?.releaseDateRIGHT?.title}<span className='text-black font-normal'> -</span></a>
                </div> */}
              {/* </div> */}
            </div>
          </div>
          
        </div>
      )}
    </>
  );
};

export default Table;
